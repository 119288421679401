import { authStore } from "./model";
import parser from "utils/parser";
import { setItem } from "utils";

const getExpirationDate = () =>
  parser.addHoursToDate(new Date(), 24).getTime() - 60 * 60 * 1000;

export const setExpirationDate = () => {
  const expirationDate = getExpirationDate();

  try {
    if (!expirationDate || typeof window === "undefined") return;
    setItem("_bup.exp", expirationDate);
  } catch (error) {
    console.error("Error storing expiration date:", error);
  }
};

export const setRefreshToken = (refreshToken) => {
  try {
    if (!refreshToken || typeof window === "undefined") return;
    setItem("_bup.rt", refreshToken);
  } catch (error) {
    console.error("Error storing refresh token:", error);
  }
};

export const setSession = ({
  refreshToken,
  accessToken,
  userId,
  role,
  serviceTier,
  accountLinkRequired,
}) => {
  setRefreshToken(refreshToken);
  setExpirationDate();
  authStore({
    ...authStore(),
    accessToken,
    userId,
    role,
    serviceTier,
    accountLinkRequired,
  });
};

export const setServiceTier = ({ serviceTier }) => {
  authStore({ ...authStore(), serviceTier });
};

export const setPermissions = ({ permissions }) => {
  authStore({ ...authStore(), permissions });
};

export const setAffiliations = ({ affiliations }) => {
  authStore({ ...authStore(), affiliations });
};

export const logout = () => {
  authStore({
    ...authStore(),
    accessToken: null,
    userId: null,
    role: null,
    permissions: [],
    accountLinkRequired: null,
  });

  try {
    console.log("clearing...");
    localStorage.clear();
  } catch (error) {
    console.error("Error clearing 'BondUP' cache during logout:", error);
  }

  window.location = "/";
};
