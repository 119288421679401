/* eslint-disable no-inner-declarations */
import "styles/globals.scss";

import { AuthProvider } from "contexts/auth/authProvider";
import { NetworkProvider } from "session/networkProvider";
import { MuiTheme /*, roboto*/ } from "session/themeProvider";
import { fetchConfig } from "server/fetchConfig";
import { useEffect, useState } from "react";
import initWorkbox from "server/workbox";
import Hotjar from "@hotjar/browser";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/es";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { MetaPixel, GoogleTagManager } from "pixels";

export default function RootLayout({ Component, pageProps }) {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fingerprint, setFingerprint] = useState(null);

  useEffect(() => {
    initWorkbox().register();
    Hotjar.init(process.env.HOTJAR_SITE_ID, process.env.HOTJAR_VERSION);
    fetchConfig().then(({ config, loading }) => {
      setConfig(config);
      setLoading(loading);
    });
  }, []);

  useEffect(() => {
    async function generateFingerprint() {
      const fpPromise = FingerprintJS.load();
      const fp = await fpPromise;
      const result = await fp.get();
      setFingerprint(result.visitorId);
    }
    generateFingerprint();
  }, []);

  return (
    <>
      <GoogleTagManager />
      <MetaPixel />
      <MuiTheme>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <NetworkProvider {...{ config, loading }}>
            <AuthProvider {...{ fingerprint }}>
              <Component {...pageProps} />
            </AuthProvider>
          </NetworkProvider>
        </LocalizationProvider>
      </MuiTheme>
    </>
  );
}
