import { decodeToken } from "react-jwt";
import {
  setAffiliations,
  setLoading,
  setPermissions,
  setSession,
  setUserInfo,
} from "contexts/store";

function useDecodeToken({ accessToken, refreshToken }) {
  /* TODO: 
    - Add user status (enabled, banned and verified) and sign out the user if the status is not enabled.
    - Delete user data from the token (city, civilStatus, employmentSituation, signUpComment).
  */

  try {
    const decodedToken = decodeToken(accessToken);

    //console.log(decodedToken);

    if (decodedToken?.exp > Date.now() / 1000) {
      setSession({
        refreshToken,
        accessToken,
        userId: decodedToken?._id,
        role: decodedToken?.role,
        serviceTier: decodedToken.serviceTier?.includes("exclusive")
          ? "exclusive"
          : decodedToken.serviceTier?.replace("bup-", "").split("-")[0] ||
            "free",
        accountLinkRequired: decodedToken?.accountLinkRequired,
      });
      setAffiliations({ affiliations: decodedToken?.affiliations });
      setPermissions({ permissions: decodedToken?.permissions });
      setUserInfo(decodedToken?.user);
    }
  } catch (error) {
    console.error("Error decoding token:", error);
  } finally {
    setLoading(false);
  }
}

export default useDecodeToken;
