import { makeVar, useReactiveVar } from "@apollo/client";

export const authStore = makeVar({
  accessToken: null,
  permissions: [],
  role: null,
  serviceTier: null,
  userId: null,
  accountLinkRequired: null,
});

export const useAuthStore = () => {
  return useReactiveVar(authStore);
};
