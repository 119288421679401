import { gql } from "@apollo/client";

const GET_EVENT_PAYMENT_CONFIRMATION = gql`
  query GetEventPaymentConfirmation(
    $userAccounting: ID
    $settleRef: String
    $sortBy: String
    $sortOrder: String
    $id: ID
  ) {
    getPayments(
      userAccounting: $userAccounting
      settleRef: $settleRef
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      total
      instances {
        _id
        amount
        status
        subject
      }
    }
    networkGetEvent(_id: $id) {
      _id
      name
      photoUrl
    }
  }
`;

export default GET_EVENT_PAYMENT_CONFIRMATION;
