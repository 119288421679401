import { gql } from "@apollo/client";

const GET_EVENT_ORGANIZER = gql`
  query GetEventOrganizerProfile($id: String!) {
    getUserProfile(_id: $id) {
      _id
      info {
        firstName
        lastName
      }
      createdAt
    }
  }
`;

export default GET_EVENT_ORGANIZER;
