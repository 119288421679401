import { updateDocument } from "contexts/server/firestore";
import { useLazyQuery, useMutation } from "gql";
import { GET_USER_PHOTO } from "mutations/account";
import { GET_USER_BENEFIT } from "queries/account";
import { useEffect } from "react";
import { setUserInfo, useUserInfo, useAuthStore } from "store";
import { detectNavigator } from "utils";

function useBenefits(userId) {
  const { affiliations, role, serviceTier, accountLinkRequired } =
    useAuthStore();
  const user = useUserInfo();

  const [refreshBenefits, { data: benefits }] = useLazyQuery(GET_USER_BENEFIT);
  const [getPicture, { called, data: photoUrl, loading }] =
    useMutation(GET_USER_PHOTO);

  useEffect(() => {
    if (userId) refreshBenefits({ id: userId });
  }, [userId, refreshBenefits]);

  useEffect(() => {
    if (userId && !called) {
      getPicture({ ids: [userId] });
      setUserInfo({
        hasFullAccess: role === "admin",
        hasBCHCard: affiliations.includes("BCH"),
        isAdmin: role === "admin",
        isPremium: serviceTier !== "free",
        accountLinkRequired,
      });
    }
  }, [called, userId, GET_USER_PHOTO]);

  useEffect(() => {
    if (benefits?._id && !loading) {
      setUserInfo({
        isPremium: !benefits.activePlan.name.includes("free"),
      });
    }
  }, [benefits, loading]);

  useEffect(() => {
    if (photoUrl[userId] && !loading) {
      setUserInfo({
        photoUrl: photoUrl[userId],
      });
    }
  }, [photoUrl, loading, userId]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const { device, display } = detectNavigator();

      updateDocument("analitycs", "devices", {
        [userId]: device,
      });
      updateDocument("analitycs", "display", {
        [userId]: display,
      });
    }
  }, [userId]);

  return {
    affiliations,
    benefits,
    loading: !userId && !user.email,
    refreshBenefits,
    user,
    accountLinkRequired,
  };
}

export default useBenefits;
